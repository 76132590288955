import axios from 'axios';

function isValidHttpUrl(value?: string) {
  let url;

  try {
    url = new URL(value!);
  } catch (error) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

const getDeviceContext = () => {
  const deviceContext = JSON.stringify({
    os: {
      name: 'Web',
    },
    userAgent: navigator.userAgent,
  });

  return btoa(deviceContext);
};

export const addAmplitudeToConfigHeader = () => {
  axios.interceptors.request.use((config) => {
    if (!isValidHttpUrl(config.url)) {
      // eslint-disable-next-line no-underscore-dangle
      const sessionId = window.amplitude?.getInstance()?._sessionId;

      Object.assign(config.headers?.common ?? {}, {
        'Segment-Device-Context': getDeviceContext(),
        ...(sessionId ? { 'Amplitude-Session-Id': sessionId } : {}),
      });
    }

    return config;
  });
};
