import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

export const analytics = new AnalyticsBrowser();

let isLoadingStarted = false;

type SegmentDestinations =
  | 'Amplitude'
  | 'Appcues'
  | 'Google AdWords New'
  | 'Google Analytics'
  | 'Segment.io'
  | 'Visual Tagger'
  | 'Fullstory Cloud Mode (Actions)';

export const isSegmentLoadingInProgress = () => {
  return isLoadingStarted;
};

export const initSegment = () => {
  try {
    if (!window.SEGMENT?.enabled) {
      return;
    }

    const isSegmentInitializedByRails = !!window.analytics;

    if (isSegmentInitializedByRails) {
      analytics.instance = window.analytics as unknown as Analytics;
      return;
    }

    if (!analytics.instance?.initialized && !isSegmentLoadingInProgress()) {
      isLoadingStarted = true;
      analytics
        .load(
          {
            writeKey: window.SEGMENT.jsKey,
            cdnURL: window.SEGMENT.cdnEndpointUrl,
          },
          {
            integrations: {
              'Segment.io': {
                apiHost: window.SEGMENT.trackingEndpointUrl,
                protocol: 'https',
              },
            },
          }
        )
        .finally(() => {
          isLoadingStarted = false;
        });
      analytics.page(window.location.pathname, {
        context: {
          screen: {
            height: window.screen.height,
            width: window.screen.width,
          },
        },
      });
    }
  } catch (error) {
    console.error('Error while initializing Segment', error);
  }
};

export const trackForDestination = (destination: SegmentDestinations, eventName: string, properties = {}) => {
  analytics.track(eventName, properties, {
    integrations: {
      All: false,
      'Segment.io': false,
      [destination]: true,
    },
  });
};
