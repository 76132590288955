/* eslint-disable no-underscore-dangle */
import { Loader } from '@googlemaps/js-api-loader';

type GoogleMaps = typeof google.maps;

class GoogleMapsWrapper {
  _mapsRef?: GoogleMaps;

  _setMapsRef(googleMapsInstance: GoogleMaps) {
    this._mapsRef = googleMapsInstance;
  }

  /**
   * maps might not be loaded - depends how quickly google's cdn responds (or if it succeeds)
   *
   * in more critical places, please use optional chaining.
   *
   * For new application roots, make sure initGoogleMaps is called
   */
  get maps(): GoogleMaps {
    return this._mapsRef!;
  }
}

export const googleRef = new GoogleMapsWrapper();

export function initGoogleMaps() {
  try {
    const loader = new Loader({
      apiKey: window.GOOGLE_MAPS_API_KEY,
      version: '3',
      libraries: ['geometry', 'places'],
    });

    loader.load().then((googleObj) => {
      googleRef._setMapsRef(googleObj.maps);
    });
  } catch (error) {
    console.error('Error while initializing Google Maps', error);
  }
}

export function mockGoogleMaps(mock: GoogleMaps) {
  googleRef._setMapsRef(mock);
}
