import { lazy } from 'react';

import { localStorage } from './storage';

const PAGE_HAS_BEEN_FORCE_REFRESHED = 'page-has-been-force-refreshed';

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasBeenForceRefreshed = JSON.parse(localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false');

    try {
      const component = await componentImport();

      localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');

      return component;
    } catch (error) {
      if (!pageHasBeenForceRefreshed) {
        /* componentImport failed so let's assume the user
        is not on latest version of the app and refresh the
        page to get the updated chunk mappings. */

        localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');

        return window.location.reload();
      }

      // Page has been reloaded but there's still an error so let's allow app to crash and raise the error
      throw error;
    }
  });

export default lazyWithRetry;
