function isSupport(storage: Storage) {
  try {
    storage.setItem('_ranger-test-key', 'hi');
    storage.getItem('_ranger-test-key');
    storage.removeItem('_ranger-test-key');
    return true;
  } catch (e) {
    return false;
  }
}

const memory: Record<string, string> = {};

const Memory = {
  setItem(cacheKey: string, data: string) {
    memory[cacheKey] = data;
  },
  getItem(cacheKey: string) {
    return memory[cacheKey];
  },
  removeItem(cacheKey: string) {
    delete memory[cacheKey];
  },
};

export const sessionStorage = isSupport(window.sessionStorage) ? window.sessionStorage : Memory;
export const localStorage = isSupport(window.localStorage) ? window.localStorage : sessionStorage;
